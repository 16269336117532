const NO_INDEX_PATHS = {
  STARTS_WITH: [
    '/sv/butik/pris',
    '/sv/butik/utvalda-produkter',
    '/sv/butik/omraden',
    '/sv/butik/veterinaren-rekommenderar',
    '/sv/butik/facets/alder',
    '/sv/butik/facets/egenskaper',
    '/sv/butik/facets/hudtyp',
    '/sv/butik/facets/hundens-storlek',
    '/sv/butik/facets/konsistens',
    '/sv/butik/facets/material',
    '/sv/butik/kampanjer/', // Target only subpages under, don't include the main page (therefore trailing slash)
  ],

  EXACT_MATCH: [
    '/sv/butik/deleted',
    '/sv/butik/bundles',
    '/sv/butik/djurtyp/farsk',
    '/sv/butik/djurtyp/godiskampanj',
    '/sv/butik/djurtyp/infor-jul-nyar',
    '/sv/butik/djurtyp/kyldynor',
    '/sv/butik/djurtyp/sommartips',
    '/sv/butik/djurtyp/vinterprodukter',
  ],
};

export { NO_INDEX_PATHS };
