const META_DESC_MAX_LENGTH = 155;
const TITLE_MAX_LENGTH = 55;

const shortenText = ({
  text,
  maxLength = META_DESC_MAX_LENGTH,
  keepFullSentence = false,
  trailingPeriod = true,
}: {
  text: string;
  maxLength: number;
  keepFullSentence?: boolean;
  trailingPeriod?: boolean;
}) => {
  if (text && text.length > maxLength) {
    const sentences = text.trim().split('. ');

    const selectedSentences = `${sentences
      .reduce((shortened: string[], sentence: string, index: number) => {
        const currentLength = shortened.join('. ').length;

        const isMaxLength = currentLength > maxLength;

        const nextSentenceWillOverrideMaxLength =
          currentLength + `. ${sentence}`.length > maxLength;

        if (
          isMaxLength ||
          (keepFullSentence && index > 0 && nextSentenceWillOverrideMaxLength)
        ) {
          return shortened;
        }

        shortened.push(sentence.trim());
        return shortened;
      }, [])
      .join('. ')}`;

    if (selectedSentences.length > maxLength) {
      return `${selectedSentences.substring(0, maxLength - 3)}...`;
    }

    if (trailingPeriod) {
      return selectedSentences.endsWith('.')
        ? selectedSentences
        : `${selectedSentences}.`;
    }

    return selectedSentences;
  }

  return text;
};

const createTitle = (
  name: string,
  titleSuffix: string,
  defaultTitle: string
) => (name ? `${name}${titleSuffix}` : defaultTitle);

const createTitleWithCategory = ({
  name,
  category,
  titleSuffix,
}: {
  name: string;
  category?: string;
  titleSuffix: string;
}) => {
  const pageTitleWithCategory = `${name} | ${category}`;

  if (
    category &&
    pageTitleWithCategory.length + titleSuffix.length < TITLE_MAX_LENGTH
  ) {
    return pageTitleWithCategory;
  }

  return name;
};

const createMetaDesc = (
  description: string,
  metaDescSuffix: string,
  defaultDescription: string
) =>
  description
    ? shortenText({
        text: description,
        maxLength: META_DESC_MAX_LENGTH - metaDescSuffix.length,
        keepFullSentence: true,
        trailingPeriod: false,
      }) + metaDescSuffix
    : defaultDescription;

export { shortenText, createTitle, createTitleWithCategory, createMetaDesc };
