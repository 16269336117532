<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <template v-if="!disableHreflang">
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
      </template>
      <template v-else>
        <Link
          v-if="canonicalLink"
          :id="canonicalLink.id"
          :rel="canonicalLink.rel"
          :href="canonicalLink.href"
          :hreflang="canonicalLink.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
      <Meta
        name="ahrefs-site-verification"
        content="13b1791d376704e82e91778d1f812a0e2e51a9e82d628e3be74c8b143f9471db"
      />
      <Meta
        name="google-site-verification"
        content="2UiRr7quUgX5-FZpnDiqVO2vV5B_IUKJSi-ULVj2SSE"
      />
    </Head>
  </Html>
</template>

<script setup lang="ts">
import { createTitle, createMetaDesc } from '#root/shared/utils/seo-utils';
import { NO_INDEX_PATHS } from '@brand/config/noindex-config';

const { locale, t } = useI18n();
const runtimeConfig = useRuntimeConfig();
const route = useRoute();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
  breadcrumbs: {
    type: Array,
    default: () => [], // [{ src: '', alt: '', path?: '' }]
  },
  disableHreflang: {
    type: Boolean,
    default: false,
  },
});

const { description, breadcrumbs, image } = props;

const seoTitle = computed(() =>
  createTitle(props.title, t('seo.title_suffix'), t('seo.title'))
);

// No need to process the description if its the same as the seo.description
const seoDescription =
  description === t('seo.description')
    ? description
    : createMetaDesc(
        description,
        t('seo.description_suffix'),
        t('seo.description')
      );

const { clientUrl } = runtimeConfig.public;

const seoImage =
  image || `${clientUrl}/ecom-assets/seo/share-${locale.value}.png`;

const { brandName } = runtimeConfig.public;

const buildUrlFromPath = (path: string) =>
  `${clientUrl}${
    path?.startsWith(`/${locale.value}`) ? path : `/${locale.value}${path}`
  }`;

const routeShouldHaveRobotsNoIndex = () => {
  const { EXACT_MATCH, STARTS_WITH } = NO_INDEX_PATHS;

  return (
    EXACT_MATCH.includes(route.path) ||
    STARTS_WITH.find((path) => route.path.startsWith(path))
  );
};

useSeoMeta({
  title: () => seoTitle.value,
  description: seoDescription,
  ogTitle: seoTitle,
  ogDescription: seoDescription,
  ogImageAlt: seoTitle,
  ogImage: seoImage,
  ogImageSecureUrl: seoImage,
  ogSiteName: brandName as string,
  ogType: 'website',
  twitterTitle: seoTitle,
  twitterDescription: seoDescription,
  twitterImageAlt: seoTitle,
  twitterImage: seoImage,
  appleMobileWebAppTitle: seoTitle,
  mobileWebAppCapable: 'yes',
  themeColor: '#000000',
  ...(routeShouldHaveRobotsNoIndex() ? { robots: 'noindex' } : {}),
});

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: {
    canonicalQueries: ['page'],
  },
});

const canonicalLink: any = computed(
  () => head.value.link?.filter((l) => l.rel === 'canonical')[0]
);

useJsonld(() => {
  if (!breadcrumbs || breadcrumbs.length <= 0) {
    return null;
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((crumb: any, index: number) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: crumb.name,
      item: buildUrlFromPath(crumb.path),
    })),
  };
});
</script>
